/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var Viewport = {};

Viewport.documentWidth = function(){
  var e = window, a = 'inner';
  
  if( !( 'innerWidth' in window ) ) {
    a = 'client';
    e = document.documentElement || document.body;
  }

   return { width : e[ a+'Width' ] , height : e[ a+'Height' ] };
};

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Nav = {};

  Nav.scrolled = function() {
    $( window ).scroll( function() {
      var scroll = $( window ).scrollTop();

      if( scroll >= 60 ) {
        $( '.nav-block' ).addClass( 'is-scrolled' );
      } else {
        $( '.nav-block' ).removeClass( 'is-scrolled' );
      }
    });
  };

  Nav.expand = function() {
    $( '.js-nav-expand' ).on( 'click', function( e ) {
      e.preventDefault();

      if( $( '.nav-block' ).hasClass( 'is-expanded' ) ) {
        $( '.nav-block' ).removeClass( 'is-expanded' );
        $( this ).removeClass( 'is-triggered' );
      } else {
        $( '.nav-block' ).addClass( 'is-expanded' );
        $( this ).addClass( 'is-triggered' );
      }
    } );
  };


  var Hero = {};

  Hero.changeBg = function() {
    $( '.js-bg-switch' ).each( function() {
      if( Viewport.documentWidth().width <= 575 ) {
        $( this ).css( 'background-image', 'url(' + $( this ).data( 'bg-mobile' ) + ')' );
      } else {
        $( this ).css( 'background-image', 'url(' + $( this ).data( 'bg-desktop' ) + ')' );
      }
    } );
  };

  Hero.imgSwitch = function() {
    $( '.js-img-switch' ).each( function() {
      if( Viewport.documentWidth().width <= 575 ) {
        $( this ).attr( 'src', $( this ).data( 'mobile' ) );
      } else {
        $( this ).attr( 'src', $( this ).data( 'desktop' ) );
      }
    } );
  };

  Hero.scrollto = function() {
    $( '.js-scrollto' ).on( 'click', function( e ) {
      e.preventDefault();

      $( 'html, body' ).animate( {
        scrollTop: $( '.hero-small' ).outerHeight() - $( '.nav-block' ).outerHeight()
      }, 300 );
    } );
  };


  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        Nav.scrolled();
        Nav.expand();
        Hero.changeBg();
        Hero.imgSwitch();
        Hero.scrollto();
        

        $( window ).on( 'resize', function() {
          Hero.changeBg();
          Hero.imgSwitch();
        } );

        // unwrap link block in content editor
        $( '.content-editor .link-block' ).unwrap().wrap( '<p></p>' );
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
