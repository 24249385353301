( function( $ ) {

	var target = $( '.js-cookie-bar' );
	var acceptBtn = $( '.js-cookie-btn' );

	var setCookie = function ( name, value, days ) {
		var expires = "";
		if ( days ) {
			var date = new Date();
			date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
			expires = "; expires=" + date.toUTCString();
		}
		document.cookie = name + "=" + (value || "") + expires + "; path=/";
	};

	if( target.length > 0 ) {
		acceptBtn.click( function() {
			setCookie( 'cookie_accepted', 1, 60 );
			$(this).parents( target.selector ).remove();
		} );
	}

} )( jQuery );